.shell-comp {
  .banner-portrait {
    height: 502px;
    max-width: 216px;
    min-width: 216px;
  }
  .banner-landscape {
    height: min-content;
    max-width: 700px;
    min-width: 700px;
  }
  .basic-info {
    display: flex;
    flex-direction: row;

    .video-thumbnail {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .banner-vertical {
      max-width: 216px;
      min-width: 216px;
      min-height: 384px;
      align-self: center;
    }

    .banner-horizontal {
      width: 100%;
      max-height: 384px;
      aspect-ratio: 16/9;
    }

    .deleted {
      cursor: default;
    }

    .thumbnail-medium {
      min-width: 177px;
      max-width: 177px;
      height: 100px;
    }
    .thumbnail-small {
      min-width: 142px;
      max-width: 142px;
      height: 80px;
    }
    .thumbnail-large {
      min-width: calc(0.8 * 336px);
      max-width: calc(0.8 * 336px);
      height: calc(0.8 * 186px);
    }
    .playlist-thumbnail {
      cursor: pointer;
    }
    .playlist-image-placeholder {
      background-color: #3a3a3c;
      border: 1px white solid;
      border-bottom: none;
    }
    .video-description {
      padding: 0 6px;
      display: flex;
      flex-direction: column;
      flex: 1 0 0;

      .video-title {
        color: #3b3939;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-overflow: ellipsis;
        cursor: pointer;
      }

      .title-two-lines {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .playlist-description {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .channel-name {
        justify-content: center;
        .channel-first-last-name {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }

  .container-horizontal {
    flex-direction: column !important;
    gap: 8px;
    padding-bottom: 16px;
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }

  .container-dashboard {
    min-width: 516px;
    max-width: 516px;
  }

  .container-highlighted {
    scroll-margin-top: 200px;
    background-color: #f4f4f4;
    border-right: 4px #3a3a3c solid;
  }

  .descripton-horizontal {
    padding: 0px 16px;
  }

  .description-vertical {
    margin-left: 6px;
    width: 40%;
  }

  .title-horizontal {
    font-size: 18px;
    line-height: 26px;
    color: #3b3939;
  }

  .title-vertical {
    font-size: 16px;
    color: #48484a;
  }

  .deleted {
    cursor: default !important;
  }

  .details-horizontal {
    font-size: 14px;
    line-height: 20px;
  }

  .details-vertical {
    font-size: 12px;
  }
}
