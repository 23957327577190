.playlist-image-container-container {
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .playlist-background {
    min-height: 10px;
    min-width: calc(100% - 20px);
    padding-left: 10px;
    padding-right: 10px;
    background-color: #636366;
    border-radius: 12px 12px 0px 0px;
    border: 1px white solid;
    border-bottom: none;
  }

  .playlist-image-container {
    border-radius: 12px 12px 12px 12px;
    position: relative;
    overflow: hidden;
    background-color: #636366;
    width: 100%;
    height: 100%;
    border: 1px white solid;

    .playlist-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .card-thumbnail {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 12px;
      margin-top: 28px;
      justify-content: center;
      align-items: center;
      width: 100%;

      img {
        width: 20%;
      }

      color: #fff;
      text-align: center;
      font-size: 90%;
      font-weight: 600;
      line-height: 100%;
    }

    .playlist-image-placeholder {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .playlist-info-bar {
      position: absolute;
      bottom: 0;
      padding: 6px 12px;
      background-color: rgba(0, 0, 0, 0.4);
      width: 100%;
      // min-height: 30px;
      color: #ffffff;
      display: flex;
      flex-direction: row;
      gap: 16px;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      align-items: center;
    }
  }
}

.playlist-image-container-exist {
  cursor: pointer;
}
