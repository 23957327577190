.md-page {
  padding: 64px 40px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 64px;

  .top {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .category-container {
      display: flex;
      width: fit-content;
      background-color: rgba(52, 167, 255, 0.1);
      height: 32px;
      padding: 12px 10px 12px 6px;
      align-items: center;
      border: 1px solid rgba(52, 167, 255, 0.2);
      border-radius: 16px;
      gap: 10px;
      color: rgba(52, 167, 255, 1);
      font-size: 12px;
      font-weight: 600;

      .category {
        background-color: white;
        border: 1px solid rgba(52, 167, 255, 0.2);
        padding: 3px 10px;
        border-radius: 14px;
        height: 24px;
        align-items: center;
        display: flex;
        font-size: 12px;
        font-weight: 500;
      }
    }

    .title {
      font-size: 42px;
      font-weight: 600;
    }

    .description {
      color: var(--Gray-600, #535862);
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      margin-top: 4px;
      max-width: 900px;
    }

    .metadata-row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 8px;

      .published-date {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .published-date-text {
          font-size: 14px;
          font-weight: 600;
          color: rgba(52, 167, 255, 1);
        }

        .published-date-value {
          font-size: 18px;
          color: rgba(53, 56, 66, 1);
        }
      }

      .copy-link-button {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px 16px;
        border: 1px solid #e7e7e9;
        border-radius: 8px;
        color: #636366;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        background: none;
        transition: all 0.2s ease;

        &:hover {
          background-color: #f5f5f5;
        }

        img {
          width: 16px;
          height: 16px;
          margin: 0;
        }
      }
    }

    img {
      width: 100%;
      height: 420px;
      object-fit: cover;
      border-radius: 12px;
      margin-top: 24px;
    }
  }

  .content {
    color: #48484a;
    font-size: 16px;
    line-height: 24px;

    h1,
    h2,
    h3 {
      color: #3a3a3c;
      font-weight: 700;
      margin: 32px 0 16px;
    }

    h1 {
      font-size: 36px;
      line-height: 44px;
    }

    h2 {
      font-size: 24px;
      line-height: 32px;
    }

    h3 {
      font-size: 20px;
      line-height: 28px;
    }

    p {
      margin-bottom: 16px;
      color: #636366;
    }

    img {
      width: 100%;
      height: 420px;
      object-fit: cover;
      border-radius: 12px;
      margin: 32px 0;
    }

    ul,
    ol {
      margin: 16px 0;
      padding-left: 24px;
      color: #636366;
    }

    li {
      margin-bottom: 8px;
    }

    strong {
      color: #3a3a3c;
      font-weight: 600;
    }

    em {
      font-style: italic;
    }
  }

  &.mobile {
    padding: 48px 20px;

    .top {
      text-align: center;
      gap: 8px;

      img {
        height: 240px;
      }

      .metadata-row {
        flex-direction: column;
        align-items: center;
        margin-bottom: 8px;
      }
    }

    .content {
      img {
        height: 240px;
      }

      h1 {
        font-size: 28px;
        line-height: 36px;
      }

      h2 {
        font-size: 20px;
        line-height: 28px;
      }

      h3 {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }

  .bottom {
    display: flex;
    flex-direction: row;
    gap: 32px;
  }
}

.share-container {
  display: flex;
  width: 1200px;
  flex-direction: column;
  gap: 20px;
  border-radius: 16px;
  border: 1px solid var(--Gray-200, #e9eaeb);
  background: var(--Gray-50, #fafafa);
  padding: 32px;
  height: fit-content;

  .share-icon {
    width: fit-content;
    height: fit-content;
    background-color: white;
    border: 1px solid var(--Gray-200, #e9eaeb);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  .share-text {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .share-title {
      font-size: 16px;
      font-weight: 600;
      color: #3a3a3c;
    }

    .share-description {
      font-size: 14px;
      font-weight: 400;
      color: #636366;
    }
  }

  input {
    border-radius: 8px;
    border: 1px solid var(--Gray-200, #e9eaeb);
    background: var(--Gray-50, #fafafa);
    padding: 8px 12px;
    font-size: 14px;
  }

  .privacy-policy {
    font-size: 12px;
    font-weight: 400;
    color: #636366;
    margin-top: -12px;
  }
}
