.page-container {
  display: flex;
  height: calc(100vh - 171px);
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden !important;
  position: relative;
}

.playlists-wrapper {
  width: 100%;
  min-width: 50vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-list-wrapper {
  height: 100%;
  width: 100%;
}

h1 {
  font-size: 2rem;
  margin-bottom: 10px;
  padding: 0;
}

.item-wrapper {
  background-color: red;
}

.inner-draggable-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  box-sizing: border-box;

  .icon-container {
    min-width: 20px;
    max-width: 20px;
    display: flex;
    align-items: center;
    margin-left: -2px;
    margin-right: 3px;

    .card-icon {
      width: 14px;
      height: 24px;
      margin-bottom: 2px;
    }
  }

  .draggable-span-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    margin-right: 8px;

    .draggable-span {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--Neutral-dark-800, #3a3a3c);
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      flex: 1 1 auto;
    }
  }

  .button-wrapper {
    display: flex;
    align-items: center;
    color: #4a5468;
    justify-content: end;
    gap: 8px;
    flex: 0 0 auto;

    .button-wrapper-privacy {
      padding-right: 32px;
    }
  }

  .btn-add {
    // color: #4A5468;
    img {
      filter: brightness(40%) saturate(0%) sepia(0%) hue-rotate(0deg)
        contrast(120%);
      width: 20px;
      height: 20px;
    }
  }
  .privacy {
    color: #4a5468;
    .img {
      width: 20px;
      height: 20px;
    }
  }
  .privacy,
  .btn-add,
  .menu-toggle {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .kebab-menu {
    display: inline-block;

    .menu-toggle {
      background: none;
      border: none;
      cursor: pointer;
      line-height: 1;
      color: #4a5468;
    }

    .btn menu-toggle {
      right: 0;
      background-color: #f9f9f9;
      min-width: 160px;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
      z-index: 1;

      .menu-item {
        color: black;
        padding: 12px 16px;
        width: 100%;
        text-decoration: none;
        display: block;

        &:hover {
          background-color: #f1f1f1;
        }
      }
    }

    &.open .menu-content {
      display: block; // Show when open
    }
  }
}
.inner-draggable-container:hover {
  .tooltip-text {
    visibility: visible;
    opacity: 1;
    transition-delay: 0.4s;
  }
}

.inner-draggable-container:not(:hover) .tooltip-text {
  opacity: 0;
  visibility: hidden;
  transition-delay: 0.2s;
}
.popover-btn-group {
  padding: 0 3px;
  .menu-item {
    display: block;
    width: 100%;
    &:focus {
      //box-shadow: none;
    }
  }
}

.dnd-sortable-tree_folder_tree-item-collapse_button {
  border: 0;
  width: 20px;
  align-self: stretch;
  transition: transform 250ms ease;
  background: url("data:image/svg+xml;utf8,<svg width='10' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 70 41'><path d='M30.76 39.2402C31.885 40.3638 33.41 40.995 35 40.995C36.59 40.995 38.115 40.3638 39.24 39.2402L68.24 10.2402C69.2998 9.10284 69.8768 7.59846 69.8494 6.04406C69.822 4.48965 69.1923 3.00657 68.093 1.90726C66.9937 0.807959 65.5106 0.178263 63.9562 0.150837C62.4018 0.123411 60.8974 0.700397 59.76 1.76024L35 26.5102L10.24 1.76024C9.10259 0.700397 7.59822 0.123411 6.04381 0.150837C4.4894 0.178263 3.00632 0.807959 1.90702 1.90726C0.807714 3.00657 0.178019 4.48965 0.150593 6.04406C0.123167 7.59846 0.700153 9.10284 1.75999 10.2402L30.76 39.2402Z' /></svg>")
    no-repeat center;
}

.dnd-sortable-tree_folder_tree-item-collapse_button-collapsed {
  transform: rotate(-90deg);
}

.watch-playlist-wrapper {
  height: 100%;
  width: 100%;

  .content-area {
    height: auto;
  }
  .card,
  .quizCard {
    height: 70vh;
  }

  .scrollable-content {
    overflow: hidden;
    scroll-snap-type: y mandatory;
    height: 100%;
  }
  .close-button {
    right: 10px;
    top: 10px;
    border: none;
    cursor: pointer;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    z-index: 10;
    margin-bottom: 90px;
  }
}

.dnd-sortable-tree_simple_tree-item-chosen {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 8px;
}

.dnd-sortable-tree_simple_tree-item-creating {
  background-color: red;
}

.playlist-wrapper {
  overflow: hidden;
  min-width: 360px;
  max-width: 360px;
  height: auto;
  background: white;
  padding: 24px 0px;
  border-right: 1px solid var(--Neutral-light-200, #e5e5ea);
  > * {
    padding: 0 24px 0 24px;
  }
  .search {
    input {
      display: flex;
      height: auto;
    }
    .search-button {
      left: 0;
      bottom: 0;
      width: 40px;
      height: 40px;
      right: unset;
      padding: 0;
    }
    > div:first-of-type {
      width: 100%;
    }
  }
  .playlists {
    // max-height: 120vh;
    overflow-y: auto;
    height: 100%;
    padding: 10px 8px 100px 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: stretch;
  }
}

.dnd-sortable-tree_simple_wrapper {
  transition: 0.3s;
  border-radius: 8px;

  .popover-privacy-group {
    border-radius: 16px;
    display: flex;
    padding: 16px 12px;
    flex-direction: column;
    align-items: flex-start;
    .privacy-header {
      color: var(--Neutral-dark-700, #48484a);
      text-align: center;
      margin-bottom: 12px;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
    }

    .privacy-item {
      display: flex;
      padding: 12px 8px;
      align-items: center;
      align-self: stretch;
      border-radius: 8px;
      background: var(--Neutral-light-10, #fff);
      border: none;
    }
    .privacy-text {
      color: var(--Neutral-dark-800, #3a3a3c);
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-left: 16px;
    }
  }
  .playlist-name {
    color: var(--Neutral-dark-700, #48484a);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .name-dropdown {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
